import { MappedVariantName, ProductKey } from '@/js/vue/shopify/types';

export interface ConfiguratorSection {
  title: string;
  bottomText?: string;
  keepColumnsOnMobile?: boolean;
  variants: Array<{
    productKey: ProductKey;
    variantName: MappedVariantName;
    variantDisplayName?: string;
    variantIcon?: number;
    horizontal?: boolean;
    horizontalVariantBoxOnMobile?: boolean;
    showPreviewImageSkeleton?: boolean;
    showPriceDifference?: boolean | 'toBase' | 'toZero' | { [key: string]: string | undefined };
    hideClearButton?: boolean;
  }>;
}

export const ConfiguratorSections: Array<ConfiguratorSection> = [
  {
    title: 'Ausführung',
    variants: [
      {
        productKey: 'main',
        variantName: 'Ausfuehrung',
        horizontal: true,
        showPreviewImageSkeleton: true,
      },
    ],
  },
  {
    title: 'Tischplatte Grösse',
    keepColumnsOnMobile: true,
    variants: [
      {
        productKey: 'main',
        variantName: 'Laenge',
      },
      {
        productKey: 'main',
        variantName: 'Breite',
      },
    ],
  },
  {
    title: 'Holzbehandlung',
    variants: [
      {
        productKey: 'holzbehandlung',
        variantName: 'Behandlung',
        horizontal: true,
        horizontalVariantBoxOnMobile: true,
        showPreviewImageSkeleton: true,
      },
    ],
  },
  {
    title: 'Tischbeine',
    variants: [
      {
        productKey: 'tischbein',
        variantName: 'AnzahlBeine',
        showPriceDifference: true,
        showPreviewImageSkeleton: true,
        horizontalVariantBoxOnMobile: true,
      },
      {
        productKey: 'tischbein',
        variantName: 'Farbe',
        showPriceDifference: true,
        showPreviewImageSkeleton: true,
        horizontalVariantBoxOnMobile: true,
      },
    ],
  },
  {
    title: 'Kabeldurchführung',
    variants: [
      {
        productKey: 'kabeldurchfuehrung',
        variantName: 'Typ',
        showPriceDifference: {
          Position: 'Links',
        },
        //showPreviewImageSkeleton: true,
      },
      {
        productKey: 'kabeldurchfuehrung',
        variantName: 'Position',
        showPriceDifference: true,
        hideClearButton: true,
      },
    ],
  },
];
