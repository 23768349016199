<template>
  <ProductVariantBox
    :product="configurator.products.main.product"
    :variant-name="variantName"
    :variant-value="variantValue"
    :price="showPriceDifference ? priceDifference : undefined"
    :is-selected="isSelected"
    :is-available="isAvailable"
    :show-preview-image-skeleton="showPreviewImageSkeleton"
    :horizontal-variant-box-on-mobile="horizontalVariantBoxOnMobile"
    @click="handleClick"
  />
</template>

<script lang="ts" setup>
import { findProductVariants, useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { computed, PropType } from 'vue';
import type { Product, ProductVariant } from '@/js/vue/shopify/queries';
import { MappedVariantName, ProductKey, VariantName } from '@/js/vue/shopify/types';
import ProductVariantBox from '@/js/vue/components/Configurator/ProductVariantBox.vue';

const configurator = useConfiguratorStore();

const props = defineProps({
  productKey: {
    type: String as PropType<ProductKey>,
    required: true,
  },
  variantName: {
    type: String as PropType<VariantName>,
    required: false,
  },
  variantValue: {
    type: String,
    required: false,
  },
  showPriceDifference: {
    type: [Boolean, String, Object] as PropType<boolean | 'toBase' | 'toZero' | { [key: string]: string | undefined }>,
    required: false,
    default: () => false,
  },
  showPreviewImageSkeleton: {
    type: Boolean,
    default: () => false,
  },
  horizontalVariantBoxOnMobile: {
    type: Boolean,
    default: () => false,
  },
});

const product = computed<Product | undefined>(() => configurator.products[props.productKey]?.product);
const baseVariant = computed<ProductVariant | undefined>(() =>
  findProductVariants({ ...product.value, isOptional: false }, { ...(configurator.selectedProductOptions[props.productKey] ?? {}), [props.variantName]: undefined }).at(0)
);

const currentVariant = computed<ProductVariant | undefined>(() =>
  props.variantValue
    ? findProductVariants(product.value, { ...(configurator.selectedProductOptions[props.productKey] ?? {}), [props.variantName]: props.variantValue }).at(0)
    : undefined
);

const isSelected = computed(() =>
  props.variantValue
    ? (configurator.selectedProductOptions[props.productKey][props.variantName] ?? '__not_selected__') === props.variantValue
    : Object.keys(configurator.selectedProductOptions[props.productKey]).length === 0
);

const isAvailable = computed(() => !props.variantValue || !!currentVariant.value?.id);

const priceDifference = computed<number | undefined>(() => {
  const currentAmount = currentVariant.value?.price.amount ?? 0;

  if (!props.showPriceDifference) {
    return undefined;
  }

  if (props.showPriceDifference === 'toZero') {
    return currentAmount;
  }

  if (props.showPriceDifference === 'toBase' || props.showPriceDifference === true) {
    return currentAmount - (baseVariant.value?.price.amount ?? 0);
  }

  if (typeof props.showPriceDifference === 'object') {
    const variant = findProductVariants(product.value, {
      ...(configurator.selectedProductOptions[props.productKey] ?? {}),
      [props.variantName]: props.variantValue,
      ...(props.showPriceDifference as Record<MappedVariantName, string>),
    }).at(0);

    return variant?.price.amount;
  }

  return undefined;
});
const handleClick = () => {
  if (!isAvailable.value) {
    return;
  }

  if (props.variantValue) {
    configurator.selectedProductOptions[props.productKey][props.variantName] = props.variantValue;
    configurator.selectCheapestProductOptions();
  } else {
    configurator.selectedProductOptions[props.productKey] = {};
  }
};
</script>
